<template>
    <div>
        <section id="hero" class="hero section dark-background">

            <div id="hero-carousel" class="carousel slide carousel-fade" data-bs-ride="carousel"
                data-bs-interval="5000">

                <div class="carousel-item active">
                    <img src="img/hero-carousel/hero-carousel-2.jpg" alt="">
                    <div class="carousel-container">
                        <h2>Under Construction</h2>
                        <p>Technical work is being done on the site</p>
                        <router-link to="/contact" class="btn btn-warning btn-lg">Contact</router-link>
                    </div>
                </div>
            </div>

        </section>
    </div>
</template>

<script>
export default {
    name: 'HomePage'
}
</script>