import Vue from 'vue'
import App from './App.vue'
import router from './router' 
Vue.config.productionTip = false

new Vue({
  router,
  mounted() {
    let fontAwesome = document.createElement('script')
    fontAwesome.setAttribute('src', 'js/web.js')
    document.head.appendChild(fontAwesome)
  },
  render: h => h(App)
}).$mount('#app')
